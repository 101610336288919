<template>
  <me-form class="me-grid-column-4" :rules="rules" ref="form">
    <me-label width="300px" title="仓库发货单号" prop="swarehouseoutno">
      <me-input clearable v-model="form.swarehouseoutno" />
    </me-label>
    <me-label width="300px" title="提货单编号" prop="sorderno">
      <me-input clearable v-model="form.sorderno" />
    </me-label>
    <me-label width="300px" title="仓库ID" prop="swarehouseid">
      <me-input clearable v-model="form.swarehouseid" />
    </me-label>
    <me-label width="300px" title="提货总量" prop="fweight">
      <me-input clearable v-model="form.fweight" />
    </me-label>
    <me-label width="300px" title="身份证号" prop="sidcardno">
      <me-input clearable v-model="form.sidcardno" />
    </me-label>
    <me-label width="300px" title="提货人" prop="sdrivername">
      <me-input clearable v-model="form.sdrivername" />
    </me-label>
    <me-label width="300px" title="车牌号" prop="scarno">
      <me-input clearable v-model="form.scarno" />
    </me-label>
    <me-label width="300px" title="提货总额" prop="famount">
      <me-input clearable v-model="form.famount" />
    </me-label>
    <me-label width="300px" title="仓库" prop="swarehouse">
      <me-input clearable v-model="form.swarehouse" />
    </me-label>
  </me-form>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      form: {
        sguid: '',
        swarehouseoutno: '',
        sorderno: '',
        swarehouseid: '',
        fweight: '',
        sidcardno: '',
        sdrivername: '',
        scarno: '',
        famount: '',
        swarehouse: ''
      },
      rules: {
        sguid: [
          { required: true, message: '主键不能为空' }
        ],
        swarehouseoutno: [
          { required: true, message: '仓库发货单号不能为空' }
        ],
        sorderno: [
          { required: true, message: '提货单编号不能为空' }
        ],
        swarehouseid: [
          { required: true, message: '仓库ID不能为空' }
        ],
        fweight: [
          { required: true, message: '提货总量不能为空' }
        ],
        sidcardno: [
          { required: true, message: '身份证号不能为空' }
        ],
        sdrivername: [
          { required: true, message: '提货人不能为空' }
        ],
        scarno: [
          { required: true, message: '车牌号不能为空' }
        ],
        famount: [
          { required: true, message: '提货总额不能为空' }
        ],
        swarehouse: [
          { required: true, message: '仓库不能为空' }
        ]
      }
    }
  },
  created () {
    const sguid = this.$route.query.sguid
    this.refreshData({ sguid })
  },
  methods: {
    ...mapActions([ 'selectLadingorderByPrimary', 'updateLadingorder' ]),
    async refreshData ({ sguid }) {
      if (sguid) {
        const data = await this.selectLadingorderByPrimary({ key: sguid })
        this.$tools.assign(this.form, { ...data })
      }
    },
    async save () {
      await this.$refs.form.validate()
      await this.updateLadingorder(this.form)
    },
    reset () {
      this.$refs.form.reset()
    }
  }
}
</script>
